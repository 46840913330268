import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'

export const Section = styled.section`
  background: ${p => p.theme.colors.maastricht};
  color: ${p => p.theme.colors.white};
  padding-bottom: 3.5rem;
  position: relative;
  ${above('md')`
    padding-bottom: 4rem;
  `}
`

export const Title = styled.h2`
  ${getTypeStyle('text')};
  margin-top: 5rem;
  margin-bottom: 4rem;
  ${above('md')`
    margin-top: 8rem;
    margin-bottom: 6rem;
    width: ${getColumnSize(8)};
    ${getTypeStyle('caption')};
  `}
  ${above('lg')`
    margin-left: ${getColumnSize(1)};
  `}
  ${above('xg')`
    margin-top: 12.5rem;
    margin-bottom: 9.75rem;
    margin-left: ${getColumnSize(2)};
    width: ${getColumnSize(7)};
    ${getTypeStyle('subtitle')};
  `}
`

export const SectionTitle = styled.h3`
  font-family: ${p => p.theme.type.fonts.headline};
  ${getTypeStyle('caption')};
  margin-bottom: 1.5rem;
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    width: ${getColumnSize(5)};
    margin-right: ${getColumnSize(1)};
    ${getTypeStyle('subtitle')};
  `}
  ${above('lg')`
    margin-left: ${getColumnSize(1)};
    width: ${getColumnSize(4)};
  `}
  ${above('xg')`
    margin-left: ${getColumnSize(2)};
    width: ${getColumnSize(3)};
  `}
`

export const SectionDescription = styled.p`
  margin-bottom: 4rem;
  opacity: 0.7;
  ${getTypeStyle('smallText')};
  ${above('sm')`
    width: ${getColumnSize(10)};
  `}
  ${above('md')`
    margin-bottom: 6rem;
    width: ${getColumnSize(6)};
    ${getTypeStyle('text')};
  `}
  ${above('lg')`
    width: ${getColumnSize(5)};
  `}
  ${above('xg')`
    width: ${getColumnSize(4)};
  `}
`
