import React from 'react'
import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'
import * as UI from '@/components/UI'

export const Section = styled.section`
  background: ${p => p.theme.colors.flash};
  position: relative;
  z-index:20;
  padding-top: 4rem;
  ${above('md')`
    padding-top: 6rem;
  `}
  ${above('lg')`
    padding-top: 8rem;
  `}
  ${above('xg')`
    padding-top: 9.75rem;
  `}
`

export const Title = styled.h3`
  font-family: ${p => p.theme.type.fonts.headline};
  ${getTypeStyle('caption')};
  margin-bottom: 1.5rem;
  width: ${getColumnSize(8)};
  margin-right: ${getColumnSize(4)};
  ${above('sm')`
    width: ${getColumnSize(6)};
    margin-right: ${getColumnSize(6)};
  `}
  ${above('md')`
    width: ${getColumnSize(4)};
    margin-right: ${getColumnSize(8)};
  `}
  ${above('xg')`
    margin-right: 0;
    padding: 0 4rem 0 2.5rem;
  `}
`

export const Description = styled.p`
  margin-bottom: 1.5rem;
  opacity: 0.8;
  ${getTypeStyle('smallText')};
  ${above('sm')`
    width: ${getColumnSize(8)};
  `}
  ${above('md')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(5)};
    margin-right: ${getColumnSize(1)};
  `}
  ${above('xg')`
    width: ${getColumnSize(4)};
    margin-right: 0;
  `}
`

export const ItemsGrid = styled(UI.Grid)`
  justify-content: center;
  padding: 2rem 0 6rem;
  ${above('lg')`
    padding: 3.5rem 0 10rem;
  `}
  ${above('xg')`
    padding: 5.25rem 0 16rem;
  `}
`

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: ${p => p.theme.type.fonts.headline};
  font-weight: 500;
  margin: 1.5rem 0;
  ${getTypeStyle('blogText')};
  width: ${getColumnSize(6)};
  ${above('md')`
    ${getTypeStyle('text')};
    width: ${getColumnSize(4)};
  `}
  ${above('lg')`
    ${getTypeStyle('subtitle')};
    width: ${getColumnSize(3)};
  `}
`

export const ItemImage = styled(UI.Image)`
  width: 4rem;
  height: 4rem;
  ${above('xg')`
  width: 4.5rem;
  height: 4.5rem;
  `}
  ${above('lg')`
    width: 5rem;
    height: 5rem;
  `}
`

export const ItemTitle = styled.h3`
  text-align: center;
  ${getTypeStyle('blogText')};
  margin-top: 0.5rem;
  max-width: 10em;
  ${above('md')`
    ${getTypeStyle('text')};
    margin-top: 0.75rem;
  `}
  ${above('xg')`
    ${getTypeStyle('subtitle')};
    margin-top: 1rem;
  `}
`

const Bottom = props => (
  <svg viewBox='0 0 1605 162' {...props}>
    <path
      fill='currentColor'
      d='M814.93801 160h.00045c24.45206 0 48.84953-.46762 73.18147-1.41107C1127.60524 149.33137 1369.75742 93.59138 1604.77365 0H1605v162H0V.1468C268.50482 103.03177 545.04717 159.99996 814.93801 160z'
    />
  </svg>
)

export const StyledBottom = styled(Bottom)`
  position: absolute;
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  color: ${p => p.theme.colors.snow};
`
