import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import * as UI from '@/components/UI'

export const Section = styled.section`
  padding-top: 5rem;
  position: relative;
  ${above('sm')`
    padding-top: 6rem;
  `}
  ${above('md')`
    padding-top: 8rem;
  `}
  ${above('lg')`
    padding-top: 10rem;
  `}
  ${above('xg')`
    padding-top: 11.25rem;
  `}
`

export const TextGrid = styled(UI.Grid)`
  justify-content: center;
`

export const TextCell = styled.div`
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: ${getColumnSize(12)};
  ${above('sm')`
     width: ${getColumnSize(10)};
   `}
  ${above('md')`
    width: ${getColumnSize(8)};
  `}
  ${above('lg')`
    width: ${getColumnSize(6)};
  `}
`

export const MembersGrid = styled(UI.Grid)`
  padding: 3.5rem 0 5rem;
  ${above('md')`
    padding: 5rem 0 8rem;
  `}
  ${above('lg')`
    padding: 7.5rem 0 12.5rem;
  `}
`

export const MemberCell = styled.div`
  width: ${getColumnSize(12)};
  ${above('sm')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(4)};
  `}
  ${above('xg')`
    width: ${getColumnSize(3)};
  `}
`
