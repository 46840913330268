import React from 'react'
import { graphql } from 'gatsby'
import Layout from '@/layout'
import NavBar from '@/components/Partials/NavBar/NavBar'
import Footer from '@/components/Partials/Footer/Footer'
import Hero from '@/components/contents/AboutIndex/Hero'
import First from '@/components/contents/AboutIndex/First'
import Second from '@/components/contents/AboutIndex/Second'
import Team from '@/components/contents/AboutIndex/Team'
import Accredited from '@/components/contents/AboutIndex/Accredited'
import Prefooter from '@/components/Partials/Prefooter/Prefooter'
import getMetaImage from '@/utils/getMetaImage'

const AboutIndexPage = ({ data }) => {
  const content = { ...data?.content?.data, slug: data?.content?.slug }

  const metaImage = getMetaImage(content)

  return (
    <Layout
      title={content.meta_title}
      description={content.meta_description}
      image={metaImage}
    >
      <NavBar />
      <Hero content={content} />
      <First content={content} />
      <Second content={content} />
      <Team content={content} />
      <Accredited content={content} />
      <Prefooter content={content} />
      <Footer />
    </Layout>
  )
}

export const pageQuery = graphql`
  query AboutIndex($locale: String) {
    content: prismicAboutPage(lang: { eq: $locale }) {
      lang
      slug: uid
      data {
        meta_title
        meta_description
        meta_image {
          localFile {
            childImageSharp {
              fixed(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        hero_title
        hero_description
        hero_image {
          image {
            localFile {
              childImageSharp {
                fluid(maxWidth: 2560, quality: 40) {
                  aspectRatio
                  src
                  sizes
                  originalName
                  srcSet
                }
              }
            }
          }
        }
        first_title
        first_sections {
          title
          description
        }
        second_title
        second_items {
          title
          image {
            localFile {
              publicURL
            }
          }
        }
        second_paragraphs {
          text
        }
        team_title
        team_description
        team_download
        team_members {
          name
          position
          twitter {
            url
          }
          linkedin {
            url
          }
          cv {
            url
          }
          image {
            localFile {
              childImageSharp {
                fluid {
                  aspectRatio
                  src
                  sizes
                  originalName
                  srcSet
                }
              }
            }
          }
        }
        accredited_title
        accredited_description
        accredited_list {
          item
        }
        prefooter_cards {
          card {
            document {
              ... on PrismicPrefootercard {
                data {
                  title
                  description
                  type {
                    uid
                    type
                    url
                    slug
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

export default AboutIndexPage
