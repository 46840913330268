import React, { Fragment } from 'react'
import * as UI from '@/components/UI'

import srcMoon from '@/assets/images/nobox-green.png'
import srcYellow from '@/assets/images/nobox-yellow.png'
import srcHeart from '@/assets/images/nobox-red.png'
import { Section, Title, SectionTitle, SectionDescription } from './First.css'

const SeriousGamesIndexFirst = ({ content }) => {
  return (
    <Section>
      <UI.Parallax top='5%' right='15%' force={1.1} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcHeart}
            alt={content.first_title}
            width='360'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax bottom='40%' right='20%' force={1.1} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(45deg)`,
            }}
            src={srcYellow}
            alt={content.first_title}
            width='360'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Parallax top='30%' left='-10%' force={1.4} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.first_title}
            width='320'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <UI.Grid>
          <Title>{content.first_title}</Title>
        </UI.Grid>
        <UI.Grid>
          {content.first_sections.map(({ title, description }) => (
            <Fragment key={title}>
              <SectionTitle>{title}</SectionTitle>
              <SectionDescription>{description}</SectionDescription>
            </Fragment>
          ))}
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexFirst
