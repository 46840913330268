import React from 'react'
import * as UI from '@/components/UI'
import srcMoon from '@/assets/images/nobox-green.png'
import {
  Section,
  TextGrid,
  TextCell,
  MembersGrid,
  MemberCell,
} from './Team.css'

const SeriousGamesIndexTeam = ({ content }) => {
  return (
    <Section>
      <UI.Parallax top='40%' right='10%' force={1.2} offsetComp={20}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0)`,
            }}
            src={srcMoon}
            alt={content.first_title}
            width='320'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <TextGrid>
          <TextCell>
            <UI.SectionTitle>{content.team_title}</UI.SectionTitle>
            <UI.SectionDescription>
              {content.team_description}
            </UI.SectionDescription>
          </TextCell>
        </TextGrid>
        <MembersGrid vertical-gutter>
          {content.team_members.map(member => (
            <MemberCell key={member.name}>
              <UI.Member {...member} download={content.team_download} />
            </MemberCell>
          ))}
        </MembersGrid>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexTeam
