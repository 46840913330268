import React from 'react'
import * as UI from '@/components/UI'
import srcYellow from '@/assets/images/nobox-yellow.png'
import {
  Section,
  TextCell,
  Title,
  Description,
  ListCell,
  List,
  Item,
} from './Accredited.css'

const SeriousGamesIndexAccredited = ({ content }) => {
  return (
    <Section>
      <UI.Parallax top='30%' left='0%' force={1.1} offsetComp={10}>
        {offSet => (
          <UI.ImageLoose
            style={{
              transform: `translateY(${offSet}px) translateZ(0) rotate(60deg)`,
            }}
            src={srcYellow}
            alt={content.accredited_title}
            width='380'
            hiddenMobile
          />
        )}
      </UI.Parallax>
      <UI.Container>
        <UI.Grid>
          <TextCell>
            <Title>{content.accredited_title}</Title>
            <Description>{content.accredited_description}</Description>
          </TextCell>
          <ListCell>
            <List>
              {content.accredited_list.map(({ item }) => (
                <Item key={item}>{item}</Item>
              ))}
            </List>
          </ListCell>
        </UI.Grid>
      </UI.Container>
    </Section>
  )
}

export default SeriousGamesIndexAccredited
