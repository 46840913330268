import React from 'react'
import * as UI from '@/components/UI'
import { Section, Grid, TextCell, ImageWrapper } from './Hero.css'

const SeriousGamesIndexHero = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <Grid>
          <TextCell>
            <UI.SectionTitle>{content.hero_title}</UI.SectionTitle>
            <UI.SectionDescription>
              {content.hero_description}
            </UI.SectionDescription>
          </TextCell>
        </Grid>
      </UI.Container>

      <ImageWrapper>
        {content.hero_image.map(img => {
          return <UI.Image src={img.image} />
        })}
      </ImageWrapper>
    </Section>
  )
}

export default SeriousGamesIndexHero
