import styled from 'styled-components'
import above from '@/utils/above'
import getColumnSize from '@/utils/getColumnSize'
import getTypeStyle from '@/utils/getTypeStyle'

export const Section = styled.section`
  padding-bottom: 7.5rem;
  position: relative;
  ${above('md')`
    padding-bottom: 8.5rem;
  `}
  ${above('lg')`
    padding-bottom: 10.5rem;
  `}
  ${above('xg')`
    padding-bottom: 12.5rem;
  `}
`

export const TextCell = styled.section`
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(5)};
    margin-right: ${getColumnSize(1)};
  `}
  ${above('xg')`
    width: ${getColumnSize(4)};
    margin: 0 ${getColumnSize(1)};
  `}
`

export const Title = styled.h2`
  ${getTypeStyle('caption')};
  font-family: ${p => p.theme.type.fonts.headline};
  margin-bottom: 1.5rem;
  max-width: 12em;
  ${above('md')`
    ${getTypeStyle('subtitle')};
    margin-bottom: 1.25rem;
  `}
`

export const Description = styled.p`
  margin-bottom: 3rem;
  ${getTypeStyle('smallText')};
  ${above('md')`
    ${getTypeStyle('text')};
  `}
`

export const ListCell = styled.ul`
  width: ${getColumnSize(12)};
  ${above('md')`
    width: ${getColumnSize(6)};
  `}
  ${above('lg')`
    width: ${getColumnSize(5)};
  `}
`

export const List = styled.ul``

export const Item = styled.li`
  margin-bottom: 1rem;
  ${getTypeStyle('smallText')};
  display: flex;
  position: relative;
  &:before {
    content: '—';
    color: ${p => p.theme.colors.blue};
    margin-right: 0.5rem;
    ${above('lg')`
      position: absolute;
      right: 100%;
    `}
  }
`
