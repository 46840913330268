import React from 'react'
import * as UI from '@/components/UI'
import {
  Section,
  Title,
  Description,
  ItemsGrid,
  Item,
  ItemImage,
  ItemTitle,
  StyledBottom,
} from './Second.css'

const SeriousGamesIndexSecond = ({ content }) => {
  return (
    <Section>
      <UI.Container>
        <UI.Grid>
          <Title>{content.second_title}</Title>
          {content.second_paragraphs.map(({ text }) => (
            <Description key={text}>{text}</Description>
          ))}
        </UI.Grid>
        <ItemsGrid>
          {content.second_items.map(({ title, image }) => (
            <Item key={title}>
              <ItemImage src={image.localFile.publicURL} alt={title} />
              <ItemTitle>{title}</ItemTitle>
            </Item>
          ))}
        </ItemsGrid>
      </UI.Container>
      <StyledBottom />
    </Section>
  )
}

export default SeriousGamesIndexSecond
